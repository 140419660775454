import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import GIECard from '../../components/GIECard/GieCard';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIETextField from '../../components/GIETextField/GieTextField';
import GIEButton from '../../components/GIEButton/GieButton';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import {FormValidator} from '../../util/form-validator';
import LoginController from '../../controller/login.controller';
import {Responsive} from '../../util/responsive';
import GIECheckBox from '../../components/GIECheckBox/GieCheckBox';
import DownloadController from '../../controller/download.controller';
import {StringUtil} from '../../util/string-util';
import ConfigUrl from '../../config/config-url';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = {
    content: {
        backgroundColor: '#202956',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        paddingBottom: '10%'
    },
    contentTablet: {
        backgroundColor: '#202956',
        height: '130vh'
    },
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '135%',
        color: '#202956',
        textAlign: 'center'
    } as React.CSSProperties,
    span: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '135%',
        color: '#202956'
    },
    spanMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '135%',
        color: '#202956'
    },
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    connexion: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#253A9F'
    } as React.CSSProperties,
    connexionMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#253A9F',
        marginLeft: '20%'
    } as React.CSSProperties
};

export default class FirstConnexion extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();
    loginController: LoginController = new LoginController();
    downloadController: DownloadController = new DownloadController();
    configUrl: ConfigUrl = new ConfigUrl();

    constructor(props: any) {
        super(props);
        this.state = {
            formControls: {
                identifier: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                email: {
                    value: '',
                    error: false,
                    errorMessage: ''

                }, confirmEmail: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                createPassword: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                confirmPassword: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                customerNo: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                confidentiality: {
                    value: false,
                    error: false,
                    errorMessage: ''
                }
            },
            moreInfoOpen: false,
            emailSend: false
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.setState({emailSend: false})
    }

    changeHandler = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'identifier' || name === 'customerNo') {
            value = StringUtil.displayCorrectContractNumber(value)
        }
        const error = this.formValidator.returnError(value, name);
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        });
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        Object.keys(formControls).forEach(name => {
            const error = this.formValidator.returnError(formControls[name].value + "", name)
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;
            if (error.error === true) {
                isError = true
            }
        });
        if (formControls["createPassword"].value !== formControls["confirmPassword"].value) {
            formControls["confirmPassword"].error = true;
            formControls["confirmPassword"].errorMessage = "Les mots de passes ne sont pas identiques";
        }
        if (formControls["email"].value !== formControls["confirmEmail"].value) {
            formControls["confirmEmail"].error = true;
            formControls["confirmEmail"].errorMessage = "Les emails ne sont pas identiques";
        }
        if (formControls.confidentiality.value !== true) {
            formControls.confidentiality.error = true;
            formControls.confidentiality.errorMessage = "";
        }
        this.setState(formControls)
        return isError;
    }

    authenticate = () => {
        if (this.checkValidForm() === false) {
            const request = {
                "noContract": this.state.formControls.identifier.value,
                "password": this.state.formControls.createPassword.value,
                "confirmPassword": this.state.formControls.confirmPassword.value,
                "email": this.state.formControls.email.value,
                "confirmEmail": this.state.formControls.confirmEmail.value,
                "customerNo": this.state.formControls.customerNo.value,
                "acceptedCGU": this.state.formControls.confidentiality.value
            }
            this.loginController.createAccount(request).then((data) => {
                this.setState({emailSend: true})
            }).catch(err => {
                this.manageError(err)
            })
        }
    }

    manageError = (err) => {
        let formControls = this.state.formControls
        if (err && err.response && err.response.status === 409) {
            formControls.identifier.error = true;
            formControls.identifier.errorMessage = 'Il y a déjà un compte ouvert avec ce numéro';
        } else if (err && err.response && err.response.status === 400) {
            formControls.identifier.error = true;
            formControls.identifier.errorMessage = "il n'y a pas de contrat avec ce numéro";
        } else if (err && err.response && err.response.status === 406) {
            formControls.identifier.error = true;
            formControls.identifier.errorMessage = "Le numéro de client et de contrat ne corresponde pas!";
        } else {
            formControls.identifier.error = true;
            formControls.identifier.errorMessage = "Une erreur s'est produite";
        }
        this.setState({formControls})
    }

    checkCGV = (event) => {
        let formControls = this.state.formControls;
        formControls.confidentiality.value = !formControls.confidentiality.value
        this.setState({formControls})
    }

    downloadCgv = () => {
        window.open(this.configUrl.getBaseBackUrlStatic() + '/cgv/CGU Agence en ligne.pdf');
    }

    openCloseMoreInfo = () => {
        this.setState({moreInfoOpen: !this.state.moreInfoOpen});
    }

    render() {
        return (
            <div className="content">
                <div style={customStyles.content}>
                    <div style={{margin: 'auto', marginTop: '10%', position: 'relative'}}>
                        <GIECard width="700px"
                                 height={(isIE && this.state.emailSend) ? '500px' : '100%'}>
                            <div style={{width: '100%', padding: '2rem'}}>
                                {this.state.emailSend === false ? (<GridContainer direction="row"
                                                                                  justifyContent="flex-start"
                                                                                  alignItems="flex-start">
                                    <GridItem xs={12}>
                                        <div>
                                            <h2 style={customStyles.title}>Inscription</h2>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Votre identifiant (n° de contrat)" required
                                                      onChange={this.changeHandler}
                                                      name="identifier" value={this.state.formControls.identifier.value}
                                                      helperText={this.state.formControls.identifier.errorMessage}
                                                      error={this.state.formControls.identifier.error}
                                                      infoText={"Le numéro de contrat doit être inscrit en majuscules, sans tiret et sans espace. "}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Votre n° de client" required
                                                      onChange={this.changeHandler}
                                                      name="customerNo" value={this.state.formControls.customerNo.value}
                                                      helperText={this.state.formControls.customerNo.errorMessage}
                                                      error={this.state.formControls.customerNo.error}
                                                      infoText={"Le numéro de client doit être inscrit en majuscules, sans tiret et sans espace. "}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Votre email" required
                                                      onChange={this.changeHandler}
                                                      name="email" value={this.state.formControls.email.value}
                                                      helperText={this.state.formControls.email.errorMessage}
                                                      error={this.state.formControls.email.error}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Confirmer votre email" required
                                                      onChange={this.changeHandler}
                                                      name="confirmEmail"
                                                      value={this.state.formControls.confirmEmail.value}
                                                      helperText={this.state.formControls.confirmEmail.errorMessage}
                                                      error={this.state.formControls.confirmEmail.error}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Votre mot de passe" required
                                                      type="password"
                                                      onChange={this.changeHandler}
                                                      name="createPassword"
                                                      value={this.state.formControls.createPassword.value}
                                                      helperText={this.state.formControls.createPassword.errorMessage}
                                                      error={this.state.formControls.createPassword.error}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Confirmer votre mot de passe" required
                                                      type="password"
                                                      onChange={this.changeHandler}
                                                      name="confirmPassword"
                                                      value={this.state.formControls.confirmPassword.value}
                                                      helperText={this.state.formControls.confirmPassword.errorMessage}
                                                      error={this.state.formControls.confirmPassword.error}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <div style={{textAlign: "justify"}}>
                                            <div>Les informations collectées à partir de ce formulaire sont transmises aux services concernés de GIE SA pour la création de votre compte.</div>
                                            <div>Pour en savoir plus sur la gestion de vos données à caractère personnel et pour exercer vos droits, <a target="_blank" href="https://gie-sa.fr/politique-de-confidentialite/">cliquez ici</a></div>

                                            <a href="javascript:void(0)" style={{marginTop: '1rem', display: 'block'}} onClick={this.openCloseMoreInfo}>En savoir plus</a>
                                            <div style={{whiteSpace: "pre-wrap", display: this.state.moreInfoOpen ? 'block' : 'none'}}>Les informations recueillies sur ce formulaire font l’objet d’un traitement informatique par GIE SA, sise 14 place des Halles – 67000 Strasbourg, ayant pour finalité la création de votre compte. Les informations marquées d’un astérisque sont obligatoires – leur non-renseignement entraîne l’impossibilité de créer votre compte. Ces informations sont à destination exclusive de GIE SA et de ses partenaires et seront conservées jusqu’à la désactivation de votre compte.{'\n'}{'\n'}
                                                Vous pouvez accéder et obtenir copie des données vous concernant, sous certaines conditions, vous pouvez vous opposer au traitement de ces données, en demander la limitation, les faire rectifier ou les faire effacer. Vous pouvez définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel après votre décès. Vous disposez également du droit à la portabilité de vos données.{'\n'}{'\n'}
                                                Pour exercer vos droits, vous pouvez nous contacter, par courrier postal au Délégué à la Protection des Données : DPO R-GDS – 14 place des Halles – 67082 Strasbourg Cedex ou en remplissant le <a href="https://r-gds.fr/contacter-le-dpo/" target="_blank">formulaire de contact du Délégué à la Protection des Données.</a>{'\n'}{'\n'}
                                                Si vous estimez, après nous avoir contactés, que vos droits sur vos données à caractère personnel ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.</div>
                                            <GIECheckBox isGrey
                                                         checked={this.state.formControls.confidentiality.value === true}
                                                         name="confidentiality"
                                                         errorText={this.state.formControls.confidentiality.errorMessage}
                                                         error={this.state.formControls.confidentiality.error}
                                                         emit={(event) => this.checkCGV(event)}
                                                         label="Je reconnais accepter et avoir pris connaissance des "
                                                         pdf="Conditions Générales d’Utilisation "
                                                         download={this.downloadCgv}/>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GridContainer
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{padding: '30px 0'}}>
                                            <GridItem>
                                                <div>
                                                    <a style={customStyles.connexion} href="/admin/login"> Retour </a>
                                                </div>
                                            </GridItem>
                                            <GridItem>
                                                <GIEButton onClick={this.authenticate} green={true}
                                                           style={{height: '50px', width: '116px'}}>
                                                    <span style={customStyles.greenButton}>Inscription</span>
                                                </GIEButton>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>) : (
                                    <div>
                                        <GridContainer direction="column"
                                                       justifyContent="center"
                                                       alignItems="center">
                                            <GridItem xs={12}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <div style={{
                                                        margin: 'auto',
                                                        marginLeft: isIE ? '50px' : '',
                                                        textAlign: 'center'
                                                    }}>
                                                        <span style={customStyles.span}>Un lien vous a été envoyé par mail afin de confirmer votre adresse mail</span>
                                                    </div>
                                                    <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                                                        <div style={{margin: '10% 0', marginLeft: isIE ? '30px' : ''}}>
                                                            <GIEIcon isMobile circle height={'25vw'} icon="mail"/>
                                                        </div>
                                                    </MediaQuery>
                                                    <MediaQuery minWidth={Responsive.getMaxTablet()}>
                                                        <div style={{margin: '10% 0', marginLeft: isIE ? '30px' : ''}}>
                                                            <GIEIcon circle height={'15vw'} icon="mail"/>
                                                        </div>
                                                    </MediaQuery>
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <div style={{textAlign: 'center'}}>
                                                    <a style={customStyles.connexion} href="/admin/login"> Retour </a>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </div>)}
                            </div>
                        </GIECard>
                    </div>
                </div>
            </div>
        );
    }
}
