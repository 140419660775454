import React from 'react';
import ReactGA from 'react-ga';

import GIECard from '../../components/GIECard/GieCard';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIETextField from '../../components/GIETextField/GieTextField';
import GIEButton from '../../components/GIEButton/GieButton';
import {FormValidator} from '../../util/form-validator';
import LoginController from '../../controller/login.controller';
import {StringUtil} from '../../util/string-util';
import userInformations from '../../userInformation/user_informations';

const customStyles = {
    content: {
        backgroundColor: '#202956',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        paddingBottom: '10%'
    },
    contentTablet: {
        backgroundColor: '#202956',
        height: '70vh'
    },
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '135%',
        color: '#202956',
        textAlign: 'center'
    } as React.CSSProperties,
    info: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        marginBottom: '16px'
    } as React.CSSProperties,
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    forgetPass: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#253A9F',
        marginTop: '-60px'
    } as React.CSSProperties,
    forgetPassMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '11px',
        lineHeight: '140%',
        color: '#253A9F',
        marginTop: '-60px'
    } as React.CSSProperties
};

export default class Login extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();
    loginController: LoginController = new LoginController();

    constructor(props) {
        super(props);
        console.log('2.0.21');
        this.state = {
            ownerFormControls: {
                identifier: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                password: {
                    value: '',
                    error: false,
                    errorMessage: ''
                }
            },
            tenantFormControls: {
                letterRef: {
                    value: '',
                    error: false,
                    errorMessage: ''
                }
            }
        }
        userInformations.removeToken();
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    getFormControl(isOwner) {
        return isOwner ? 'ownerFormControls' : 'tenantFormControls';
    }

    changeHandler = (event, isOwner) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'identifier') {
            value = StringUtil.displayCorrectContractNumber(value)
        }
        const error = this.formValidator.returnError(value, name);
        const formGroup = this.getFormControl(isOwner);
        this.setState({
            [formGroup]: {
                ...this.state[formGroup],
                [name]: {
                    ...this.state[formGroup][name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        });
    }

    checkValidForm = (isOwner) => {
        const formGroup = this.getFormControl(isOwner);
        let formControls = this.state[formGroup];
        let isError = false;
        Object.keys(formControls).forEach(name => {
            const error = this.formValidator.returnError(formControls[name].value, name)
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;

            if (error.error === true) {
                isError = true
            }
        });
        this.setState(formControls)
        return isError;
    }

    authenticate = () => {
        const formGroup = this.getFormControl(true);
        if (this.checkValidForm(true) === false) {
            const request = {
                username: this.state[formGroup].identifier.value,
                password: this.state[formGroup].password.value
            }
            this.loginController.login(request).then((user: any) => {
                userInformations.setRole('admin');
                userInformations.setNoContract(user.contract)
                userInformations.setUserName(user.userName)
                userInformations.setToken(user.access_token)
                setTimeout(() => window.location.href = "/admin/accueil", 500);
            }).catch(err => {
                this.manageError(err, true)
            })
        }
    }

    authenticateTenant = () => {
        const formGroup = this.getFormControl(false);
        if (this.checkValidForm(false) === false) {
            const request = {
                letterRef: this.state[formGroup].letterRef.value
            }
            this.loginController.loginTenant(request).then((user: any) => {
                userInformations.setRole('tenant');
                userInformations.setNoContract(user.contract)
                userInformations.setToken(user.access_token)
                window.location.href = "/admin/rendez-vous";
            }).catch(err => {
                this.manageError(err, false)
            })
        }
    }

    manageError = (err, isOwner) => {
        const formGroup = this.getFormControl(isOwner);
        const formControls = this.state[formGroup];
        const secondField: string = isOwner ? 'password' : 'letterRef';
        if (err && err.response && err.response.status === 401) {
            formControls[secondField].error = true;
            if (isOwner) {
                formControls.password.errorMessage = 'Mauvais mot de passe';
            } else {
                formControls.letterRef.errorMessage = 'Mauvaise référence courrier ou pas d’intervention prévue';
            }
        } else if (err && err.response && err.response.status === 404 && isOwner) {
            formControls.identifier.error = true;
            formControls.identifier.errorMessage = "Le compte pour ce numéro de contrat n’existe pas. Créez-le en cliquant sur Créer un compte. ";
        } else if (err && err.response && err.response.status === 403) {
            if (isOwner) {
                formControls.identifier.error = true;
                formControls.identifier.errorMessage = "Le compte n'est pas vérifié,vérifiez votre boite mail";
            } else {
                formControls.letterRef.error = true;
                formControls.letterRef.errorMessage = "Impossible de modifier cette intervention";
            }
        } else {
            if (isOwner) {
                formControls.identifier.error = true;
                formControls.identifier.errorMessage = "Une erreur s'est produite";
            }

            formControls[secondField].error = true;
            formControls[secondField].errorMessage = "Une erreur s'est produite";
        }

        this.setState({formControls})
    }

    render() {
        return (
            <div className="content">
                <div style={customStyles.content}>
                    <div style={{margin: 'auto', marginTop: '10%', position: 'relative'}}>
                        <form>
                            <GIECard width="410px" height="100%">
                                <div style={{width: '100%', padding: '10% 10% 5%'}}>
                                    <GridContainer direction="row"
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start">
                                        <GridItem xs={12}>
                                            <h2 style={customStyles.title}>J’accède à mon espace</h2>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <GIETextField label="Votre identifiant (n° de contrat)"
                                                          required
                                                          autocomplete="username"
                                                          placeholder="n° de contrat"
                                                          name="identifier"
                                                          onChange={e => this.changeHandler(e, true)}
                                                          value={this.state.ownerFormControls.identifier.value}
                                                          helperText={this.state.ownerFormControls.identifier.errorMessage}
                                                          error={this.state.ownerFormControls.identifier.error}
                                                          infoText={"Le numéro de contrat doit être inscrit en majuscules, sans tiret et sans espace. "}/>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <GIETextField label="Votre mot de passe"
                                                          required
                                                          autocomplete="current-password"
                                                          type="password"
                                                          name="password"
                                                          onChange={e => this.changeHandler(e, true)}
                                                          value={this.state.ownerFormControls.password.value}
                                                          helperText={this.state.ownerFormControls.password.errorMessage}
                                                          error={this.state.ownerFormControls.password.error}
                                            />
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div style={{marginTop: '-4vh', marginLeft: '10px'}}>
                                                    <a style={customStyles.forgetPass}
                                                       href="/admin/oublie-de-mot-de-passe">Mot de passe oublié</a>
                                                </div>
                                                <div style={{marginTop: '-4vh', marginRight: '10px'}}>
                                                    <a style={customStyles.forgetPass} href="/admin/creer-un-compte">Créer
                                                        un compte </a>
                                                </div>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <div style={{marginTop: '4vh', marginLeft: '100px'}}>
                                                <GIEButton onClick={this.authenticate} green={true}
                                                           style={{height: '50px', width: '116px'}}>
                                                    <span style={customStyles.greenButton}>Connexion</span>
                                                </GIEButton>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </GIECard>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
